import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { TraitScore } from '../../models/applications';

interface AssessmentSumProgressBarProps {
  item: TraitScore;
}

const AssessmentSumProgressBar = ({ item }: AssessmentSumProgressBarProps) => {
  const { i18n } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  const { sten_score_percentage, trait } = item;

  const traitDetails = {
    id: item.trait.id,
    group_id: item.trait.group_id,
    icon: item.trait.icon,
    name: '',
    display_name: '',
    title: '',
    short_description: '',
    long_description: '',
  };
  if (i18n.language === 'ar') {
    traitDetails.name = item.trait.name_ar
      ? item.trait.name_ar
      : item.trait.name;
    traitDetails.display_name = item.trait.display_name_ar
      ? item.trait.display_name_ar
      : item.trait.name;
    traitDetails.title = item.trait.title_ar
      ? item.trait.title_ar
      : item.trait.title;
    traitDetails.short_description = item.trait.short_description_ar
      ? item.trait.short_description_ar
      : item.trait.short_description;
    traitDetails.long_description = item.trait.long_description_ar
      ? item.trait.long_description_ar
      : item.trait.long_description;
  } else {
    traitDetails.name = item.trait.name;
    traitDetails.display_name = item.trait.display_name;
    traitDetails.title = item.trait.title;
    traitDetails.short_description = item.trait.short_description;
    traitDetails.long_description = item.trait.long_description;
  }

  return (
    <div
      className={`progress-wrap ${
        i18n.dir() === 'rtl' ? 'progress-wrap-rtl' : ''
      } ${traitDetails.group_id === null ? 'pr-3 pt-3' : ''}`}
    >
      <span className="progress-title d-block">
        <img height={20} src={trait.icon} alt="" />
        <strong>{traitDetails.display_name}</strong>
        {traitDetails.group_id === null ? (
          <div
            dangerouslySetInnerHTML={{
              __html: traitDetails.long_description,
            }}
          ></div>
        ) : (
          <p>{traitDetails.short_description}</p>
        )}
      </span>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: sten_score_percentage + '%' }}
          aria-valuenow={sten_score_percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <span
            className={`progress-count 
            ${direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''}`}
          >
            {sten_score_percentage.toFixed(0)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AssessmentSumProgressBar;
