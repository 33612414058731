import * as d3 from 'd3';
import { mainColor } from '../../utils/theme';

let series = 0;
const RadarChart = {
  draw: function (id, d, options, length, groupData) {
    const cfg = {
      radius: 5,
      w: 450,
      h: 450,
      factor: 1,
      factorLegend: 0.85,
      levels: 6,
      maxValue: 1,
      radians: 2 * Math.PI,
      opacityArea: 0.5,
      ToRight: 5,
      TranslateX: 100,
      TranslateY: 100,
      ExtraWidthX: 300,
      ExtraWidthY: 300,
      showLegend: true,
    };

    const width = cfg.w + cfg.ExtraWidthX;
    const height = cfg.h + cfg.ExtraWidthY;

    if ('undefined' !== typeof options) {
      for (var i in options) {
        if ('undefined' !== typeof options[i]) {
          cfg[i] = options[i];
        }
      }
    }
    cfg.maxValue = Math.max(
      cfg.maxValue,
      d3.max(d, function (i) {
        return d3.max(
          i.map(function (o) {
            return o.value;
          }),
        );
      }),
    );
    const allAxis = d[0];
    const total = allAxis.length;
    const radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2);
    d3.select(id).selectAll('svg').remove();

    const g = d3
      .select(id)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr(
        'transform',
        `translate(${cfg.ExtraWidthX / 2}, ${cfg.ExtraWidthY / 2})`,
      );

    //Circular segments
    for (let j = 0; j < cfg.levels - 1; j++) {
      let levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
      g.selectAll('.levels')
        .data(allAxis)
        .enter()
        .append('svg:line')
        .attr('x1', function (d, i) {
          return (
            levelFactor * (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
          );
        })
        .attr('y1', function (d, i) {
          return (
            levelFactor * (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
          );
        })
        .attr('x2', function (d, i) {
          return (
            levelFactor *
            (1 - cfg.factor * Math.sin(((i + 1) * cfg.radians) / total))
          );
        })
        .attr('y2', function (d, i) {
          return (
            levelFactor *
            (1 - cfg.factor * Math.cos(((i + 1) * cfg.radians) / total))
          );
        })
        .attr('class', 'line')
        .style('stroke', 'grey')
        .style('stroke-opacity', '0.75')
        .style('stroke-width', '2px')
        .attr(
          'transform',
          'translate(' +
            (cfg.w / 2 - levelFactor) +
            ', ' +
            (cfg.h / 2 - levelFactor) +
            ')',
        );
    }

    series = 0;

    const axis = g
      .selectAll('.axis')
      .data(allAxis)
      .enter()
      .append('g')
      .attr('class', 'axis');

    axis
      .append('line')
      .attr('x1', cfg.w / 2)
      .attr('y1', cfg.h / 2)
      .attr('x2', function (d, i) {
        return (
          (cfg.w / 2) * (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
        );
      })
      .attr('y2', function (d, i) {
        return (
          (cfg.h / 2) * (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
        );
      })
      .attr('class', 'line')
      .style('stroke', function (d, i) {
        if (d.value < 0) {
          return 'grey';
        }
        if (groupData.length > 1) {
          if (i <= groupData[1].startPoint) {
            return '#007573';
          } else if (i <= groupData[2].startPoint) {
            return '#27A4A1';
          } else {
            return '#65D6D2';
          }
        } else {
          return '#27A4A1';
        }
      })
      .style('stroke-width', '2px');

    axis
      .append('svg')
      .attr('x', function (d, i) {
        let val =
          (cfg.w / 2.57) * // 225 always
            (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
          120 * Math.sin((i * cfg.radians) / total);
        return val;
      })
      .attr('y', function (d, i) {
        let val = 0;
        if (i === 0) {
          val =
            (cfg.h / 2.57) * (1 - Math.cos((i * cfg.radians) / total)) -
            90 * Math.cos((i * cfg.radians) / total);
        } else {
          val =
            (cfg.h / 2.57) * (1 - Math.cos((i * cfg.radians) / total)) -
            110 * Math.cos((i * cfg.radians) / total);
        }
        return val;
      })
      .attr('class', 'custom-trait-icon')
      .html(function (d) {
        return d.icon;
      })
      .select('.custom-trait-icon>svg')
      .attr('width', '110')
      .attr('height', '100')
      .attr('fill', mainColor);

    axis
      .append('text')
      .attr('class', 'legend')
      .text(function (d) {
        return d.name;
      })
      .style('font-family', 'sans-serif')
      .style('font-size', '11px')
      .attr('fill', function (d, i) {
        if (d.value < 0) {
          return 'grey';
        }
        if (groupData.length > 1) {
          if (i <= groupData[1].startPoint) {
            return '#007573';
          } else if (i <= groupData[2].startPoint) {
            return '#27A4A1';
          } else {
            return '#65D6D2';
          }
        } else {
          return '#27A4A1';
        }
      })
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .attr('transform', function (d, i) {
        const x =
          (cfg.w / 2) *
            (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
          60 * Math.sin((i * cfg.radians) / total);
        const y =
          (cfg.h / 2) * (1 - Math.cos((i * cfg.radians) / total)) -
          20 * Math.cos((i * cfg.radians) / total);
        let deg = 90 - (360 / length) * i;
        let trans = 0 - d.name.length * 2;
        if (deg < -90) {
          deg = deg + 180;
          trans = d.name.length * 2;
        }

        return (
          'rotate(' +
          deg +
          ',' +
          x +
          ',' +
          y +
          ') translate(' +
          trans +
          ', ' +
          -15 +
          ')'
        );
      })
      .attr('x', function (d, i) {
        return (
          (cfg.w / 2) *
            (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
          60 * Math.sin((i * cfg.radians) / total)
        );
      })
      .attr('y', function (d, i) {
        return (
          (cfg.h / 2) * (1 - Math.cos((i * cfg.radians) / total)) -
          20 * Math.cos((i * cfg.radians) / total)
        );
      });

    /*Code to append curve */
    if (cfg.showLegend) {
      const svg = d3
        .select('#chart svg')
        .append('g')
        .attr('class', 'wrapper')
        .attr(
          'transform',
          `rotate(9, ${width / 2}, ${height / 2}) translate(${width / 2}, ${
            height / 2
          })`,
        );

      ////////////////////////////////////////////////////////////
      //////////////////// Scales & Data /////////////////////////
      ////////////////////////////////////////////////////////////

      //The start date number and end date number of the labels in a year
      const data = groupData;

      //Creates a function that makes SVG paths in the shape of arcs with the specified inner and outer radius
      const arc = d3
        .arc()
        //   .cornderRadius(10)
        .innerRadius((height * 0.9) / 2)
        .outerRadius((height * 0.9) / 2 + 30)
        .cornerRadius(15);

      //Creates function that will turn the label data into start and end angles
      const pie = d3
        .pie()
        .value(function (d) {
          return d.length;
        })
        .padAngle(0.01)
        .sort(null);

      ////////////////////////////////////////////////////////////
      //////////////////// Create the Slices /////////////////////
      ////////////////////////////////////////////////////////////

      //Draw the arcs themselves
      svg
        .selectAll('.labelArc')
        .data(pie(data))
        .enter()
        .append('path')
        .attr('class', 'labelArc')
        .style('fill', function (d, i) {
          if (1 > i && groupData[1] !== undefined) {
            return '#65D6D2';
          }
          if (1 > i && groupData[1] === undefined) {
            return '#007573';
          } else if (i === 1) {
            return '#27A4A1';
          } else {
            return '#007573';
          }
        })
        .attr('id', function (d, i) {
          return 'labelArc_' + i;
        })
        .attr('d', arc);

      //Append the label names within the arcs
      svg
        .selectAll('.labelText')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'labelText')
        .style('font-family', 'sans-serif')
        .style('font-weight', 'bold')
        .style('font-size', '21px')
        .attr('fill', 'white')
        .attr('x', 5) //Move the text from the start angle of the arc
        .attr('dy', 22) //Move the text down
        .attr('dx', function (d, i) {
          return d.length * 10 * 118 - d.label.length * 6.5;
        })
        .append('textPath')
        .attr('xlink:href', function (d, i) {
          return '#labelArc_' + i;
        })
        .text(function (d) {
          return d.label;
        });
      /*code end for append curve*/
    }

    d.forEach(function (y, x) {
      let dataValues = [];
      g.selectAll('.nodes').data(y, function (j, i) {
        dataValues.push([
          (cfg.w / 2) *
            (1 -
              (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.sin((i * cfg.radians) / total)),
          (cfg.h / 2) *
            (1 -
              (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.cos((i * cfg.radians) / total)),
        ]);
      });
      dataValues.push(dataValues[0]);
      g.selectAll('.area')
        .data([dataValues])
        .enter()
        .append('polygon')
        .attr('class', 'radar-chart-serie' + series)
        // .style("stroke-width", "2px")
        // .style("stroke",'#007573')
        .attr('points', function (d) {
          var str = '';
          for (var pti = 0; pti < d.length; pti++) {
            str = str + d[pti][0] + ',' + d[pti][1] + ' ';
          }
          return str;
        })
        .style('fill', function (j, i) {
          return '#007573';
        })
        .style('fill-opacity', cfg.opacityArea)
        .on('mouseover', function (d) {
          let z = 'polygon.' + d3.select(this).attr('class');
          g.selectAll('polygon').transition(200).style('fill-opacity', 0.1);
          g.selectAll(z).transition(200).style('fill-opacity', 0.7);
        })
        .on('mouseout', function () {
          g.selectAll('polygon')
            .transition(200)
            .style('fill-opacity', cfg.opacityArea);
        });
      series++;
    });
  },
};

export default RadarChart;
